import { Dialog } from '@headlessui/react';
import Modal from 'components/Modal';
import { USER_DETAILS_TYPES } from 'constants/enums';
import { useAmplifyAuth } from 'hooks/auth-hooks';
import { useCallback, useState } from 'react';
import { BiCog, BiHistory, BiLogOutCircle } from 'react-icons/bi';
import { BsBank, BsCreditCard } from 'react-icons/bs';
import { FiArrowRight, FiUser } from 'react-icons/fi';
import { GrLocation } from 'react-icons/gr';
import { MdHeadsetMic } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers/routes';
import { toastError } from 'utils/toast';

const SidebarWidget = () => {
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const { signOut } = useAmplifyAuth();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    const res = await signOut();
    if (res) {
      navigate(ROUTES.LOGIN);
    } else {
      toastError();
    }
  }, [signOut, navigate]);

  const sidebarItems = [
    // {
    //   icon: TbLayoutGridAdd,
    //   action: () => {
    //     /* do something */
    //   },
    // },
    // {
    //   icon: HiArrowsRightLeft,
    //   action: () => {
    //      do something
    //   },
    // },
    {
      icon: BiHistory,
      action: () => {
        navigate(ROUTES.TRANSACTIONS);
      },
    },
    // {
    //   icon: FiUserPlus,
    //   action: () => {
    //     /* do something */
    //   },
    // },
    {
      icon: FiUser,
      action: () => {
        navigate(ROUTES.VIEW_DETAILS, {
          state: {
            type: USER_DETAILS_TYPES.ACCOUNT,
            mode: 'view',
          },
        });
      },
    },
    {
      icon: BsBank,
      action: () => {
        navigate(ROUTES.VIEW_DETAILS, {
          state: {
            type: USER_DETAILS_TYPES.BANK,
            mode: 'view',
          },
        });
      },
    },
    {
      icon: BsCreditCard,
      action: () => {
        navigate(ROUTES.VIEW_DETAILS, {
          state: {
            type: USER_DETAILS_TYPES.IDENTIFICATION,
            mode: 'view',
          },
        });
      },
    },
    // {
    //   icon: FiStar,
    //   action: () => {
    //     /* do something */
    //   },
    // },
    {
      icon: MdHeadsetMic,
      action: () => {
        setShowHelpModal(true);
      },
    },

    // {
    //   icon: BiCog,
    //   action: () => {
    //     /* do something */
    //   },
    // },
  ];

  return (
    <div className="flex w-full flex-row items-center justify-between rounded-sm bg-white shadow-lg shadow-neutral-300 md:w-fit md:flex-col">
      <div className="flex flex-row items-center justify-center space-y-1 px-1 py-1.5 md:flex-col md:py-1 md:px-1.5">
        {sidebarItems.map((item, index) => (
          <button
            key={index}
            className="border-r-2 border-gray-300 p-2 px-2.5 md:border-b-2 md:border-r-0 md:px-2"
            onClick={item.action}
          >
            <item.icon className="text-2xl text-secondary" />
          </button>
        ))}
      </div>
      <button className="p-2.5" onClick={handleLogout}>
        <BiLogOutCircle className="text-2xl text-secondary" />
      </button>
      <Modal isOpen={showHelpModal} onClose={() => setShowHelpModal(false)}>
        <Dialog.Title
          as="h3"
          className="items-center justify-center text-center text-2xl font-medium leading-6 text-secondary"
        >
          Helpdesk
        </Dialog.Title>
        <div className="flex flex-col gap-3 p-5">
          <a
            href="https://www.goescrow.com.au/helpdesk-app"
            rel="noreferrer"
            target="_blank"
          >
            <div className="rounded-sm bg-white px-6 py-4 drop-shadow-xl">
              <div className="flex flex-row justify-between">
                <h3 className="text-xl text-primary">General Helpdesk</h3>
                <FiArrowRight className="text-2xl text-primary" />
              </div>
              <p className="mt-4">
                For general assistance, app feedback, friendly contact and
                commercial sales assistance.
              </p>
            </div>
          </a>
          <a
            href="https://www.goescrow.com.au/disputedesk-app"
            rel="noreferrer"
            target="_blank"
          >
            <div className="rounded-sm bg-white px-6 py-4 drop-shadow-xl">
              <div className="flex flex-row justify-between">
                <h3 className="text-xl text-primary">Transaction Disputes</h3>
                <FiArrowRight className="text-2xl text-primary" />
              </div>
              <p className="mt-4">
                For assistance with specific transaction disputes.
              </p>
            </div>
          </a>
        </div>
      </Modal>
    </div>
  );
};

export default SidebarWidget;
