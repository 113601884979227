import { Link, Outlet } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import logo from 'assets/images/logo-withtext-horizontal.png';
import { useUserRequests } from 'hooks/user-hooks';
import { useMemo } from 'react';
import { getinitials } from 'utils/parsers';
import SidebarWidget from 'components/widgets/SidebarWidget';

const Main = () => {
  const { getUserDetails } = useUserRequests();

  const {
    data: userData,
    isLoading,
    refetch,
  } = useQuery(['userDetails'], getUserDetails, { refetchInterval: false });

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const fullname = useMemo(() => {
    if (!userDetails) return '';
    return `${userDetails.first_name} ${userDetails.last_name}`;
  }, [userDetails]);

  const initials = useMemo(() => {
    return getinitials(fullname);
  }, [fullname]);
  return (
    <div className="flex h-auto min-h-screen flex-1 flex-col items-center bg-mainBG pt-0 pb-10 sm:pt-3 md:pb-0">
      <div className="relative flex w-11/12 flex-1 flex-col items-stretch self-center sm:max-w-[1080px]">
        <div className="flex w-full flex-row items-center justify-between">
          <Link to="/">
            <img src={logo} alt="GoEscrow Logo" className="my-5 flex h-7" />
          </Link>
          <div className="flex flex-row items-center space-x-5">
            {/* <button type="button">
              <BiSearch className="hidden text-2xl text-gray-500 sm:flex" />
            </button>
            <button type="button">
              <BiBell className="hidden text-2xl text-gray-500 sm:flex" />
            </button> */}
            <div className="flex cursor-default flex-row items-center space-x-1">
              <div className="relative flex items-center justify-center rounded-full bg-primary p-5 ring-2 ring-gray-400">
                <h3 className="absolute font-semibold text-white">
                  {initials}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="min-h-screen rounded-sm bg-offwhiteBG p-3 ring-1 ring-neutral-300 sm:h-auto sm:min-h-1/4 sm:p-5">
          <div className="flex min-h-[600px] w-full flex-col md:flex-row md:space-x-4">
            <div className="mb-4 hidden sm:flex md:mb-0">
              <SidebarWidget />
            </div>
            <Outlet />
          </div>
        </div>
        <div className="sm::flex absolute bottom-5 hidden w-full justify-center font-light text-secondary">
          Copyright {new Date().getUTCFullYear()}. All Right Reserved. GoEscrow
          Pty Ltd
        </div>
      </div>
    </div>
  );
};

export default Main;
