import { useQuery } from '@tanstack/react-query';
import BankDetails from 'components/forms/BankDetails';
import BasicDetails from 'components/forms/BasicDetails';
import DocumentDetails from 'components/forms/DocumentDetails';
import { USER_DETAILS_TYPES } from 'constants/enums';
import { useAmplifyAuth } from 'hooks/auth-hooks';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastError } from 'utils/toast';

const ViewDetails = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    getUserDetails,
    updateUserBankDetails,
    updateUserDetails,
    updateUserAddress,
    updateUserIdentification,
  } = useUserRequests();
  const { updateUserAttributes, verifyUpdateVerification } = useAmplifyAuth();
  const { dealNavigationHelper } = useDealsRequests();
  const [editMode, setEditMode] = useState<'edit' | 'view'>();
  const location = useLocation();

  const type =
    location.state && location.state.type
      ? location.state.type
      : USER_DETAILS_TYPES.ACCOUNT;

  const mode = useMemo(
    () => editMode || location?.state?.mode || 'view',
    [editMode, location.state]
  );

  console.log('[Log] mode', { mode });

  const deal = location?.state?.deal || {};

  const { data: userData, refetch } = useQuery(
    ['userDetails'],
    getUserDetails,
    {
      refetchInterval: false,
    }
  );

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const submitBasicDetails = async (data: any) => {
    setIsSubmitting(true);

    // TODO: Add this call
    // {
    //   email,
    //   name: `${first_name} ${last_name}`,
    //   given_name: first_name,
    //   family_name: last_name,
    // }
    // const resAws = await updateUserAttributes(
    //   pick(data, ['first_name', 'last_name', 'email', 'birth_date']),
    // );
    // if (!resAws) {
    //   setIsSubmitting(false);
    //   return;
    // }
    const resBasic = await updateUserDetails(data.basicDetails);
    const resAddress = await updateUserAddress(data.address);
    if (resBasic.status && resAddress.status) {
      refetch();
      setEditMode('view');
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);

      if (!resBasic.status && resBasic.data) {
        toastError(`Error: ${resBasic.data}`);
        toastError(`Error: ${resBasic.data}`);
      } else if (!resAddress.status && resAddress.data) {
        toastError(`Error: ${resAddress.data}`);
      } else {
        toastError();
      }
    }
  };

  const submitDocumentDetails = async (data: any) => {
    setIsSubmitting(true);
    const res = await updateUserIdentification(data);
    if (res.status) {
      refetch();
      setEditMode('view');
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      if (res.data) {
        toastError(`Error: ${res.data}`);
      } else {
        toastError();
      }
    }
  };

  const submitBankDetails = async (data: any) => {
    setIsSubmitting(true);
    const res = await updateUserBankDetails(data);
    if (res && res.status) {
      refetch();
      if (deal) {
        setTimeout(() => {
          setIsSubmitting(false);
          userDetails.bank_account = res.data;

          console.log(deal.toString(), userDetails.toString());
          dealNavigationHelper(deal, userDetails, true);
        }, 500);
      } else {
        setEditMode('view');
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
      if (res.data) {
        toastError(`Error: ${res.data}`);
      } else {
        toastError();
      }
    }
  };

  const selectedData = useMemo(() => {
    switch (type) {
      case USER_DETAILS_TYPES.ACCOUNT: {
        return {
          ...userDetails.address,
          email: userDetails.email || '',
          first_name: userDetails.first_name || '',
          last_name: userDetails.last_name || '',
          phone: userDetails.phone || '',
          reference: userDetails.reference || '',
          birth_date: userDetails.birth_date,
        };
      }
      case USER_DETAILS_TYPES.BANK: {
        return userDetails.bank_account;
      }
      case USER_DETAILS_TYPES.IDENTIFICATION: {
        return userDetails.identifications && userDetails.identifications.length
          ? userDetails.identifications[userDetails.identifications.length - 1]
          : undefined;
      }
    }
  }, [userDetails, type]);

  const Title = useMemo(() => {
    switch (type) {
      case USER_DETAILS_TYPES.ACCOUNT:
        return 'Personal Information';
      case USER_DETAILS_TYPES.BANK:
        return 'Bank Details';
      case USER_DETAILS_TYPES.IDENTIFICATION:
        return 'Identification Documents';
    }
  }, [type]);

  const Content = useMemo(() => {
    switch (type) {
      case USER_DETAILS_TYPES.ACCOUNT:
        if (mode === 'edit') {
          return (
            <BasicDetails
              data={selectedData}
              onContinue={submitBasicDetails}
              isUpdate
            />
          );
        }
        return <BasicDetails readonly data={selectedData} isUpdate />;
      case USER_DETAILS_TYPES.BANK:
        if (mode === 'edit') {
          return <BankDetails onContinue={submitBankDetails} />;
        }
        return <BankDetails readonly data={selectedData} />;
      case USER_DETAILS_TYPES.IDENTIFICATION:
        if (mode === 'edit') {
          return (
            <DocumentDetails
              onContinue={submitDocumentDetails}
              data={selectedData}
            />
          );
        }
        return <DocumentDetails readonly data={selectedData} />;
      default:
        return <div />;
    }
  }, [type, selectedData, mode]);

  const enableEdit = useCallback(() => {
    setEditMode('edit');
  }, []);

  const cancelEdit = useCallback(() => {
    setEditMode('view');
  }, []);

  return (
    <div className="flex w-full flex-col bg-white py-4 px-4">
      <div className="flex flex-row justify-between">
        <h1 className="text-xl font-semibold">Account Details</h1>
        {editMode !== 'edit' ? (
          <button onClick={enableEdit}>
            <h4 className="font-semibold text-primary">Edit</h4>
          </button>
        ) : (
          <button onClick={cancelEdit}>
            <h4 className="font-semibold text-primary">Cancel</h4>
          </button>
        )}
      </div>

      {/* TODO: Temp */}
      <h2 className="text font-semibold text-primary">{Title}</h2>
      {Content}
    </div>
  );
};

export default ViewDetails;
