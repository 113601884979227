import TextInput from 'components/TextInput';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from 'utils/styles';

import logoHorizontal from 'assets/images/logo-withtext-horizontal.png';
import { useAmplifyAuth } from 'hooks/auth-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { ROUTES } from 'routers/routes';
import { toastError } from 'utils/toast';
import add from 'date-fns/add';
import { Timer } from 'components/Timer';

const TwoFactor = () => {
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [resendActiveTime, setResendActiveTime] = useState<Date>(
    add(new Date(), { minutes: 1 })
  );
  const [resendActive, setResendActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const email = useMemo(
    () =>
      location.state && location.state.email ? location.state.email : undefined,
    [location]
  );

  const { confirmSignUp, resendConfirmSignUp } = useAmplifyAuth();
  const { createUser, getUserDetails } = useUserRequests();

  useEffect(() => {
    guard();
  }, []);

  const guard = async () => {
    if (!email) {
      navigate(ROUTES.LOGIN);
      return;
    }
    return;
  };

  const isVerifyDisabled = useMemo(() => {
    if (!isSuccess && (!code || code.length < 6 || isLoading)) return true;
    return false;
  }, [code, isLoading]);

  const handleContinue = useCallback(async () => {
    if (isSuccess) {
      navigate(ROUTES.VERIFICATION_PROMPT);
    }
  }, [isSuccess]);

  const handleVerifyNow = useCallback(async () => {
    setIsLoading(true);
    if (isVerified) {
      createAccount();
    } else {
      const res = await confirmSignUp(email || '', code);
      if (res) {
        setIsVerified(true);
        createAccount();
      } else {
        toastError();
        setIsLoading(false);
      }
    }
  }, [code, isVerified]);

  const handleResendConfirmationCode = useCallback(async () => {
    try {
      await resendConfirmSignUp(email || '');
      setResendActive(false);
      setResendActiveTime(add(new Date(), { minutes: 5 }));
    } catch (e) {
      toastError();
      setResendActiveTime(add(new Date(), { seconds: 15 }));
      setResendActive(false);
    }
  }, [setResendActive, setResendActiveTime, email]);

  const createAccount = async (fallback?: boolean) => {
    setTimeout(async () => {
      const res = await getUserDetails();
      try {
        createUser();
      } catch {
        //
      }

      setIsLoading(false);
      if (res && res.status) {
        setSuccess(true);
      } else {
        if (fallback) {
          toastError();
          return;
        } else {
          setIsLoading(true);
          createAccount(true);
        }
      }
    }, 2000);
  };

  const Content = useMemo(() => {
    if (isSuccess) {
      return (
        <>
          <div>
            <h1 className="text-3xl font-semibold text-secondary  sm:text-3xl">
              Congratulations
            </h1>
            <h2 className="mt-2 text-xl font-light">
              Account has been created. In order to send and receive Escrow we
              need to verify your identity.
            </h2>
          </div>

          <div className="h-4" />

          <button
            onClick={handleContinue}
            className="btn-main w-full self-center py-2 sm:self-start"
          >
            <div className="flex flex-row justify-center">
              <h2 className="text-xl font-semibold">Verify Identity</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-3xl text-white" />
            </div>
          </button>
          {/* TODO: unsupported flow */}
          {/* <p className="text-lg font-medium text-primary underline">
            <Link to="/home">Skip. I will do it later</Link>
          </p> */}
        </>
      );
    }
    return (
      <>
        <div>
          <h1 className="text-lg font-semibold text-secondary sm:text-3xl">
            Verification
          </h1>
          <h2 className="mt-1 font-light">
            Enter 6-digit number that was sent to your Email Address
          </h2>
        </div>

        <TextInput
          value={code}
          onChange={(e) => setCode(e.currentTarget.value)}
          containerClassName="w-full"
          className="mt-4 py-2 text-center text-2xl font-medium tracking-widest"
          id="otp"
          name="otp"
          minLength={6}
          maxLength={6}
          required
          placeholder="••••••"
        />

        <div className="h-4" />

        <button
          onClick={handleVerifyNow}
          disabled={isVerifyDisabled}
          className="btn-main w-full self-center py-2 sm:self-start"
        >
          <div className="flex flex-row justify-center">
            <h2 className="text-lg font-semibold">Verify Now</h2>
            <BsFillArrowRightCircleFill className="ml-3 text-2xl text-white" />
          </div>
        </button>
        {resendActive ? (
          <button type="button" onClick={handleResendConfirmationCode}>
            <h5
              className={
                'flex-row items-center text-center font-semibold text-primary underline'
              }
            >
              Resend code
            </h5>
          </button>
        ) : (
          <Timer
            date={resendActiveTime}
            setComplete={() => {
              setResendActive(true);
            }}
          />
        )}
      </>
    );
  }, [
    handleVerifyNow,
    isSuccess,
    isVerifyDisabled,
    resendActive,
    resendActiveTime,
    handleResendConfirmationCode,
  ]);

  return (
    <div className="flex flex-1 items-center justify-center">
      <div
        className={`flex w-10/12 flex-col ${styles.flexCenter} mt-5 space-y-6 text-center sm:w-[45%]`}
      >
        <img
          src={logoHorizontal}
          alt="GoEscrow Logo"
          className="mt-10 mb-5 flex h-auto w-[50%] flex-1 self-center"
        />
        {Content}
      </div>
    </div>
  );
};

export default TwoFactor;
