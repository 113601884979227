/* eslint-disable quotes */
/* eslint camelcase: 0 */

import React, { useCallback, useMemo, useState } from 'react';

import { Dropdown } from 'components/Dropdown';
import TextInput from 'components/TextInput';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { isEmpty } from 'lodash';
import { countries } from 'utils/countries';

type Props = {
  onContinue?: (data: any) => void;
  data?: any;
  readonly?: boolean;
};

export const AUStates = [
  'New South Wales',
  'Victoria',
  'Queensland',
  'South Australia',
  'Western Australia',
  'Tasmania',
  'Northern Territory',
  'Australian Capital Territory',
];

const DocumentTypes = [
  { name: 'Passport', value: 'passport' },
  { name: "Driver's License", value: 'drivers_license' },
];

export const DocumentDetails: React.FC<Props> = ({
  data,
  onContinue,
  readonly,
}) => {
  const [hasChangedDate, setChangedDate] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<Date>(
    data?.expiry_year
      ? new Date(
          // Parses to double digit properly. Used to bug out when outside of RN debug mode
          `${data?.expiry_year}-${parseInt(data?.expiry_month).toLocaleString(
            'en-US',
            {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }
          )}-01`
        )
      : new Date()
  );
  const [stateValue, setStateValue] = useState(data?.state || null);
  const [typeValue, setTypeValue] = useState(data?.type || undefined);
  const [countryValue, setCountryValue] = useState(
    data?.country || 'Australia'
  );
  const [number, setNumber] = useState<string>(data?.number || '');

  const isContinueEnabled = useMemo(
    () =>
      (typeValue || data?.type) &&
      (stateValue || data?.state || countryValue || data?.country) &&
      (!isEmpty(number) || data?.number) &&
      hasChangedDate,
    [typeValue, stateValue, countryValue, number, hasChangedDate]
  );

  const renderDropdownItem = useCallback((item: any) => {
    const val = typeof item === 'string' ? item : item.value;
    return val === 'passport' ? 'Passport' : "Driver's License";
  }, []);

  const onSelectTypeOption = useCallback((item: any) => {
    setTypeValue(item.value);
  }, []);

  const onSelectStateOption = useCallback((item: any) => {
    setStateValue(item);
    setCountryValue(null);
  }, []);

  const onCountrySelectOption = useCallback((value: any) => {
    setCountryValue(value.name);
    setStateValue(null);
  }, []);

  const handleDateChange = useCallback((value: any) => {
    setDateValue(value);
    setChangedDate(true);
  }, []);

  const handleContinue = useCallback(async () => {
    if (!onContinue) return;

    const typeVal = typeValue || data?.type;
    const monthVal = dateValue ? dateValue.getMonth() + 1 : data?.expiry_month;
    const yearVal = dateValue ? dateValue.getFullYear() : data?.expiry_year;

    onContinue({
      type: typeVal || data?.type,
      number: number || data?.number,
      expiry_month: monthVal,
      expiry_year: yearVal,
      state: stateValue || data?.state,
      country: countryValue || data?.country,
    });
  }, [data, typeValue, number, dateValue, stateValue, countryValue]);

  if (readonly && (!data || !data.type)) return <></>;

  return (
    <form className="my-3 w-full" action="#" method="POST">
      <div className="flex flex-col space-y-3">
        <div className="flex-1 flex-col space-y-3">
          <div>
            <label className="pl-1 text-sm text-secondary">Document Type</label>
            <Dropdown
              items={DocumentTypes}
              selected={typeValue}
              onChange={onSelectTypeOption}
              renderItem={renderDropdownItem}
              disabled={readonly}
            />
          </div>
        </div>
        {typeValue || data?.type || readonly ? (
          <div className="flex-1 flex-col space-y-3">
            <div>
              <label className="pl-1 text-sm text-secondary">Expiry</label>
              <ReactDatePicker
                selected={dateValue}
                onChange={handleDateChange}
                disabled={readonly}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 text-lg font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
              />
            </div>
            <TextInput
              id="number"
              name="number"
              label="Number"
              type="text"
              disabled={readonly}
              placeholder="1203546"
              value={number}
              onChange={(value) => setNumber(value.currentTarget.value)}
            />
            {(typeValue || data?.type || '').toLowerCase() === 'passport' ? (
              <div>
                <label className="pl-1 text-sm text-secondary">Country</label>
                <Dropdown
                  items={countries}
                  selected={countryValue}
                  onChange={onCountrySelectOption}
                  disabled={readonly}
                />
              </div>
            ) : (
              <div>
                <label className="pl-1 text-sm text-secondary">State</label>
                <Dropdown
                  items={AUStates}
                  selected={stateValue}
                  onChange={onSelectStateOption}
                  disabled={readonly}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>

      {!readonly ? (
        <button
          className="mt-6 w-full self-center rounded-lg bg-primary py-3 px-4 font-bold text-white hover:bg-primary-120 disabled:bg-gray-300 sm:w-1/2 sm:self-start sm:pr-2"
          type="button"
          onClick={handleContinue}
          disabled={!isContinueEnabled}
        >
          <div className="flex flex-row justify-center">
            <h2 className="text-xl font-semibold">Continue</h2>
            <BsFillArrowRightCircleFill className="ml-3 text-3xl text-white" />
          </div>
        </button>
      ) : null}
    </form>
  );
};

export default DocumentDetails;
