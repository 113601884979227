/* eslint camelcase: 0 */
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import * as yup from 'yup';

import { LoadScript, LoadScriptProps } from '@react-google-maps/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import PlacesAutocomplete from 'react-places-autocomplete';

import TextInput from 'components/TextInput';
import { GOOGLE_API_KEY } from 'constants/api';
import { useForm } from 'react-hook-form';
import { DateTimeFormats } from 'utils/parsers';
import FormInput from 'components/FormInput';
import { Dropdown } from 'components/Dropdown';

type Props = {
  onContinue?: (data: any) => void;
  data?: any;
  readonly?: boolean;
  isUpdate?: boolean;
};

export const AUStates = [
  'New South Wales',
  'Victoria',
  'Queensland',
  'South Australia',
  'Western Australia',
  'Tasmania',
  'Northern Territory',
  'Australian Capital Territory',
];

const BasicDetails: React.FC<Props> = ({
  onContinue,
  data,
  readonly,
  isUpdate = false,
}) => {
  const [hasChangedDate, setChangedDate] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<Date | null>(
    new Date('2004-01-01')
  );
  const [addressText, setAddressText] = useState<string>('');
  const [addressLine, setAddressLine] = useState<string>('');
  const [selectValue, setSelectedValue] = useState<string | undefined>();

  const onSelectOption = useCallback((value: string) => {
    setSelectedValue(value);
  }, []);

  const schema = yup
    .object({
      first_name: yup.string().required('First Name required'),
      last_name: yup.string().required('Last Name required'),
      email: yup.string().email().required('Email required'),
      street_number: yup.string().required('Street number is required'),
      street: yup.string().required('Street'),
      unit_number: yup.string(),
      suburb: yup.string().required('Suburb required'),
      postcode: yup.string().required('Postcode required'),
    })
    .required();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    ...(data ? { defaultValues: data } : {}),
  });

  const dataAddressLine = useMemo(
    () => (data && data.street ? `${data.street_number} ${data.street}` : ''),
    [data]
  );

  useEffect(() => {
    if (isUpdate && data) {
      setAddressLine(`${data.street_number} ${data.street}`);
    }
  }, []);

  const isContinueEnabled = useMemo(
    () =>
      (hasChangedDate || isUpdate) &&
      isValid &&
      (selectValue || data.state || isUpdate),
    [hasChangedDate, isValid, selectValue, isUpdate, data, addressLine]
  );

  const stateValueDisplay = useMemo(() => {
    // if (selectValue && selectValue.length) return selectValue.toString();
    if (data && data.state) return data.state;
    return 'Select';
  }, [selectValue, data]);

  const handleChangeAddressText = useCallback((address: string) => {
    setAddressText(address);
  }, []);

  const handleSelectAddress = useCallback(
    (address: string, placeId: string, suggestion: any) => {
      // console.log('[Log] suggestion', { suggestion });
    },
    []
  );

  const shouldShowAddressFields = useMemo(
    () => readonly || isUpdate || addressLine !== '',
    [isUpdate, readonly, addressLine]
  );

  const handleContinue = useCallback(
    async (formData: any) => {
      if (
        !onContinue ||
        !formData ||
        !isValid ||
        // !addressLine || TODO: Remove when stuff avail
        (!selectValue && !data.state)
      )
        return;

      onContinue({
        address: {
          ...(formData ? formData : data),
          state: selectValue || data.state,
          country: 'Australia',
        },
        basicDetails: {
          first_name: formData.first_name || data.first_name,
          last_name: formData.last_name || data.last_name,
          phone: data.phone,
          birth_date: dateValue
            ? format(dateValue, DateTimeFormats.FormDate)
            : data.birth_date,
        },
      });
    },
    [dateValue, data, isValid, selectValue, onContinue]
  );

  const handleDateChange = useCallback((date: Date) => {
    setDateValue(date);
    setChangedDate(true);
  }, []);

  return (
    <form className="my-3 w-full" onSubmit={handleSubmit(handleContinue)}>
      <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
        <div className="flex-1 flex-col space-y-3">
          <FormInput
            control={control}
            disabled={readonly || !isUpdate}
            name="first_name"
            label="First Name"
            type="text"
            autoComplete="fname"
            placeholder="Enter First Name"
          />
          <FormInput
            control={control}
            disabled={readonly || !isUpdate}
            name="last_name"
            label="Last Name"
            type="text"
            autoComplete="lname"
            placeholder="Enter Last Name"
          />

          <div>
            <label className="pl-1 text-sm text-secondary">Date of Birth</label>
            <DatePicker
              selected={dateValue}
              onChange={handleDateChange}
              disabled={readonly}
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 text-lg font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
            />
          </div>
        </div>
        <div className="flex-1 flex-col space-y-3">
          <FormInput
            control={control}
            disabled
            name="email"
            type="email"
            label="Email Address"
            autoComplete="email"
            placeholder="Enter Email Address"
          />
        </div>
      </div>

      <p className="mt-6 font-semibold text-primary">Address Details</p>
      {/* TODO:  Google API Key broken. manual input alternative */}
      {/* <LoadScript
        googleMapsApiKey={GOOGLE_API_KEY}
        libraries={libs as LoadScriptProps['libraries']}
      >
        <PlacesAutocomplete
          value={addressText}
          onChange={handleChangeAddressText}
          // @ts-expect-error - type library unmaintained
          onSelect={handleSelectAddress}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: '10 Matlet Street',
                  className:
                    'w-full sm:w-[50%] min-h-[44px] rounded-sm border border-gray-300 px-3 text-lg font-light text-gray-900 placeholder:text-gray-500  focus:border-primary focus:outline-none focus:ring-primary',
                })}
              />
              <div className="flex w-full flex-col space-y-2 border bg-white drop-shadow-md sm:w-fit sm:min-w-[50%]">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </LoadScript> */}

      <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
        <div className="flex-1 flex-col space-y-3">
          <FormInput
            control={control}
            disabled={readonly}
            name="street_number"
            label="Street Number"
            type="text"
            placeholder="10"
          />
          <FormInput
            control={control}
            disabled={readonly}
            name="street"
            label="Street"
            type="text"
            placeholder="Matlet Street"
          />

          <FormInput
            control={control}
            disabled={readonly}
            name="unit_number"
            label="Unit Number (optional)"
            type="text"
            placeholder="3B"
          />
        </div>

        <div className="flex-1 flex-col space-y-3">
          <FormInput
            control={control}
            disabled={readonly}
            name="suburb"
            label="Town/Suburb"
            type="text"
            placeholder="Nedlands"
          />
          <div>
            <label className="pl-1 text-sm text-secondary">
              Select Region, State or District
            </label>
            <Dropdown
              items={AUStates}
              disabled={readonly}
              selected={selectValue}
              onChange={onSelectOption}
            />
          </div>
          <FormInput
            control={control}
            disabled={readonly}
            name="postcode"
            label="Postcode"
            type="text"
            placeholder="4301"
          />
        </div>
      </div>
      {!readonly ? (
        <div className="mt-6 w-full sm:w-1/2 sm:pr-2">
          <button
            disabled={!isContinueEnabled}
            className="btn-main w-full self-center py-2 sm:self-start"
          >
            <div className="flex flex-row justify-center">
              <h2 className="text-xl font-semibold">Continue</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-3xl text-white" />
            </div>
          </button>
        </div>
      ) : null}
    </form>
  );
};

export default BasicDetails;
