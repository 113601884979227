/* eslint-disable camelcase */
import axiosInstance from 'api';
import { API_ENDPOINTS } from 'constants/api';
import { USER_DETAILS_TYPES } from 'constants/enums';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers/routes';
import { DealForm, InviteForm, NoteForm } from 'type/deals-dto';

export const useDealsRequests = () => {
  const navigate = useNavigate();

  const getDealFees = async () => {
    const { data, status } = await axiosInstance.get(API_ENDPOINTS.DEALS_FEES);
    return { data, status };
  };

  const getDeals = async (params = {}) => {
    const { data, status } = await axiosInstance.get(API_ENDPOINTS.DEALS, {
      params,
    });
    return { data, status };
  };

  const getDealDetails = async (id: string) => {
    const { data, status } = await axiosInstance.get(
      `${API_ENDPOINTS.DEALS}/${id}`
    );
    return { data, status };
  };

  const acceptDeal = async (id: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS}/${id}/accept`
    );
    return { data, status };
  };
  const closeDeal = async (id: string, two_factor_code?: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS}/${id}/close`,
      {
        two_factor_code: two_factor_code || '',
      }
    );
    return { data, status };
  };

  const dealNavigationHelper = (deal: any, user: any, replace?: boolean) => {
    if (!deal) return;
    if (deal.transaction_type === 'send')
      sendDealNavigationHelper(deal, user, replace);
    else requestDealNavigationHelper(deal, user, replace);
  };

  const sendDealNavigationHelper = (
    deal: any,
    user: any,
    replace?: boolean
  ) => {
    let designatedRoute = '';

    if (deal.initiator === 'me') {
      if (deal.payment_status === 'pending') {
        designatedRoute = ROUTES.TRANSFER_PAYMENT;
      } else if (deal.payment_status === 'paid') {
        designatedRoute = ROUTES.TRANSACTION_DETAILS;
      }
    } else {
      // request bank details
      if (!user.bank_account) {
        navigate(ROUTES.VIEW_DETAILS, {
          state: {
            type: USER_DETAILS_TYPES.BANK,
            mode: 'edit',
            deal,
          },
        });
        return;
      } else if (deal.status === 'pending') {
        designatedRoute = ROUTES.ACCEPT_DEAL;
      } else {
        designatedRoute = ROUTES.TRANSACTION_DETAILS;
      }
    }

    console.log('[Log] deal', { deal });

    // navigation logic
    if (replace) {
      navigate(designatedRoute, { state: { dealId: deal.id } });
    }
    navigate(designatedRoute, { state: { dealId: deal.id } });
  };

  const requestDealNavigationHelper = (
    deal: any,
    user: any,
    replace?: boolean
  ) => {
    let designatedRoute = '';
    // designated route conditionals
    if (deal.initiator === 'me') {
      if (!user.bank_account) {
        navigate(ROUTES.VIEW_DETAILS, {
          state: {
            type: USER_DETAILS_TYPES.BANK,
            mode: 'edit',
            deal,
          },
        });
        return;
      } else designatedRoute = ROUTES.TRANSACTION_DETAILS;
    } else {
      switch (deal.status) {
        case 'pending':
          designatedRoute = ROUTES.ACCEPT_DEAL;
          break;
        case 'completed':
        case 'cancelled':
          designatedRoute = ROUTES.TRANSACTION_DETAILS;
          break;
        case 'accepted':
          if (deal.payment_status === 'pending') {
            designatedRoute = ROUTES.TRANSFER_PAYMENT;
          } else {
            designatedRoute = ROUTES.TRANSACTION_DETAILS;
          }
          break;
      }
    }

    // navigation logic
    if (replace) {
      navigate(designatedRoute, { state: { dealId: deal.id } });
    }
    navigate(designatedRoute, { state: { dealId: deal.id } });
  };

  const createDeal = async (form: DealForm) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.DEALS,
      form
    );
    return { data, status };
  };

  const bypassPayment = async (id: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS}/${id}/paid`
    );
    return { data, status };
  };

  const sendInvite = async (form: InviteForm, id: string) => {
    const { data, status } = await axiosInstance.post(
      `${API_ENDPOINTS.DEALS}/${id}/invite`,
      form
    );
    return { data, status };
  };

  const getDealFromToken = async (token: string) => {
    const { data, status } = await axiosInstance.get(
      `${API_ENDPOINTS.DEALS_TOKEN}/${token}`
    );
    return { data, status };
  };

  const acceptDealFromToken = async (token: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS_TOKEN}/${token}/accept`
    );
    return { data, status };
  };

  const addDealNote = async (form: NoteForm, id: string) => {
    const { data, status } = await axiosInstance.post(
      `${API_ENDPOINTS.DEALS}/${id}/notes`,
      form
    );
    return { data, status };
  };

  const deleteDealNote = async (noteId: string, dealId: string) => {
    const { data, status } = await axiosInstance.delete(
      `${API_ENDPOINTS.DEALS}/${dealId}/notes/${noteId}`
    );
    return { data, status };
  };

  const payByPoli = async (id: string) => {
    const { data, status } = await axiosInstance.get(
      `${API_ENDPOINTS.DEALS}/${id}/pay/poli`
    );
    return { data, status };
  };

  return {
    getDealFees,
    getDeals,
    getDealDetails,
    createDeal,
    acceptDeal,
    closeDeal,
    bypassPayment,
    sendInvite,
    getDealFromToken,
    acceptDealFromToken,
    dealNavigationHelper,
    addDealNote,
    deleteDealNote,
    payByPoli,
  };
};
