import { useQuery } from '@tanstack/react-query';
import BankDetails from 'components/forms/BankDetails';
import BasicDetails from 'components/forms/BasicDetails';
import DocumentDetails from 'components/forms/DocumentDetails';
import { useUserRequests } from 'hooks/user-hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers/routes';
import { toastError } from 'utils/toast';

export enum VERIFICATION_STEP {
  BASIC_DETAILS = 'basic-details',
  DOCUMENT_DETAILS = 'document-details',
  DOCUMENT_UPLOAD = 'document-upload',
  BANK_DETAILS = 'bank-details',
}

const Verification = () => {
  const [step, setStep] = useState<VERIFICATION_STEP>(
    VERIFICATION_STEP.BASIC_DETAILS
  );
  const navigate = useNavigate();
  const {
    getUserDetails,
    updateUserDetails,
    updateUserAddress,
    updateUserIdentification,
  } = useUserRequests();

  const {
    data: userData,
    isLoading,
    refetch,
  } = useQuery(['userDetails'], getUserDetails, { refetchInterval: false });

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (userDetails && !isLoading) {
      if (
        userDetails.birth_date &&
        userDetails.address &&
        userDetails.identifications &&
        userDetails.identifications.length > 0
      ) {
        navigate(ROUTES.HOME);
      } else {
        if (!userDetails.birth_date || !userDetails.address) {
          setStep(VERIFICATION_STEP.BASIC_DETAILS);
          return;
        }
        if (
          !userDetails.identifications ||
          userDetails.identifications.length <= 0
        ) {
          setStep(VERIFICATION_STEP.DOCUMENT_DETAILS);
          return;
        }
        // if (!userDetails.bank_account) {
        //   setStep(VERIFICATION_STEP.BANK_DETAILS);
        //   return;
        // }
      }
    }
  }, [userDetails, isLoading]);

  const title = useMemo(() => {
    switch (step) {
      case VERIFICATION_STEP.BASIC_DETAILS:
        return 'Personal Information';
      case VERIFICATION_STEP.DOCUMENT_DETAILS:
        return 'Identification Documents';
      case VERIFICATION_STEP.BANK_DETAILS:
        return 'Bank Details';
    }
  }, [step]);

  const handleSubmitBasicDetails = async (data: any) => {
    setIsSubmitting(true);
    const resBasic = await updateUserDetails(data.basicDetails);
    const resAddress = await updateUserAddress(data.address);
    setIsSubmitting(false);
    if (resBasic.status && resAddress.status) {
      refetch();
    } else {
      if (!resBasic.status && resBasic.data) {
        toastError(`Error: ${resBasic.data}`);
      } else if (!resAddress.status && resAddress.data) {
        toastError(`Error: ${resAddress.data}`);
      } else {
        toastError();
      }
    }
  };

  const handleSubmitDocumentDetails = async (data: any) => {
    setIsSubmitting(true);
    const res = await updateUserIdentification(data);
    setIsSubmitting(false);
    if (res.status) {
      refetch();
    } else {
      if (res.data) {
        toastError(`Error: ${res.data}`);
      } else {
        toastError();
      }
    }
  };

  const Content = useMemo(() => {
    switch (step) {
      case VERIFICATION_STEP.BASIC_DETAILS:
        return (
          <BasicDetails
            onContinue={handleSubmitBasicDetails}
            data={userDetails}
          />
        );
      case VERIFICATION_STEP.DOCUMENT_DETAILS:
        return <DocumentDetails onContinue={handleSubmitDocumentDetails} />;
      case VERIFICATION_STEP.BANK_DETAILS:
        return <BankDetails />;
    }
  }, [step, userDetails]);

  return (
    <div className="flex w-full flex-col rounded-sm bg-white px-4 pt-6 pb-10 sm:px-5">
      <h1 className="text-2xl font-semibold">Account Details</h1>

      {/* TODO: Temp */}
      <p className="mt-6 font-semibold text-primary">{title}</p>
      {Content}
    </div>
  );
};

export default Verification;
