import { Auth } from 'aws-amplify';

export const useAmplifyAuth = () => {
  const getAuth = async () => {
    try {
      const currentSession = await Auth.currentSession();
      if (currentSession) {
        console.log('[AUTH] CurrentSession', { currentSession });
        return currentSession.getIdToken().getJwtToken();
      }
      throw new Error();
    } catch (e) {
      console.log('[AUTH] auth-error: getAuth failed', e);
      return false;
    }
  };

  // TODO: Form data model
  const signUp = async (data: any) => {
    const { firstName, lastName, email, phoneNumber, password } = data;
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          phone_number: phoneNumber, // E.164 format (e.g. +612312321)
          name: `${firstName} ${lastName}`,
          given_name: firstName,
          family_name: lastName,
        },
        autoSignIn: {
          enabled: true,
        },
      });
      return user;
    } catch (error) {
      console.log('[AUTH] auth-error: signUp failed', error);
      return false;
    }
  };

  const confirmSignUp = async (email: string, code: string) => {
    try {
      const user = await Auth.confirmSignUp(email, code);
      return user;
    } catch (error) {
      console.log('[AUTH] auth-error: confirmSignUp failed', error);
      return false;
    }
  };

  const resendConfirmSignUp = async (email: string) => {
    try {
      const res = await Auth.resendSignUp(email);
      return res;
    } catch (error) {
      console.log('[AUTH] auth-error: confirmSignUp failed', error);
      return false;
    }
  };

  // TODO: Form data model
  const signIn = async (data: any) => {
    const { email, password } = data;
    try {
      const user = await Auth.signIn(email, password);
      return {
        success: true,
        data: user,
      };
    } catch (error) {
      return {
        success: false,
        data: error,
      };
    }
  };

  const forgotPassword = async (username: string) => {
    try {
      await Auth.forgotPassword(username);
      return true;
    } catch (error) {
      console.log('[AUTH] auth-error: forgotPassword failed', error);
      return false;
    }
  };

  const forgotPasswordSubmit = async (
    username: string,
    code: string,
    newPassword: string
  ) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      return true;
    } catch (error) {
      console.log('[AUTH] auth-error: resetPassword failed', error);
      return false;
    }
  };

  const updateUserAttributes = async (data: any) => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      await Auth.updateUserAttributes(user, data);

      return true;
    } catch (error) {
      console.log('[AUTH] auth-error: update user details failed', error);
      return false;
    }
  };

  const verifyUpdateVerification = async (code: string, type?: string) => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit(type || 'email', code);
      return true;
    } catch (error) {
      console.log('[AUTH] auth-error: verifyUpdateAttribute failed', error);
      return false;
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      return true;
    } catch (error) {
      console.log('[AUTH] auth-error: signout failed', error);
      return false;
    }
  };

  return {
    getAuth,
    signUp,
    confirmSignUp,
    resendConfirmSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    updateUserAttributes,
    verifyUpdateVerification,
    signIn,
    signOut,
  };
};
