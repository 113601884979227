import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTimeFormats } from 'utils/parsers';

const Transactions = () => {
  const { getDeals, dealNavigationHelper } = useDealsRequests();
  const { getUserDetails } = useUserRequests();
  const navigate = useNavigate();
  const { data: dealsRes, isLoading } = useQuery(['dealsList'], () =>
    getDeals()
  );
  const { data: userRes } = useQuery(['userDetails'], getUserDetails);

  const dealsData = useMemo(
    () => (dealsRes && dealsRes.status ? dealsRes?.data : {}),
    [dealsRes]
  );
  const userDetails = useMemo(
    () => (userRes && userRes.status ? userRes?.data : {}),
    [userRes]
  );

  const handleViewDeal = useCallback(
    (deal: any) => () => {
      dealNavigationHelper(deal, userDetails, false);
    },
    [userDetails]
  );
  const renderItem = (item: any, index: any) => {
    item;

    let transactorType = 'Buyer';

    if (item.initiator && item.initiator.toLowerCase() === 'me') {
      transactorType = item.transaction_type === 'send' ? 'Buyer' : 'Seller';
    } else {
      transactorType = item.transaction_type === 'send' ? 'Seller' : 'Buyer';
    }

    return (
      <button
        key={item.reference}
        className="flex w-full flex-row justify-between rounded-sm bg-white p-3 drop-shadow-lg"
        type="button"
        onClick={handleViewDeal(item)}
      >
        <div className="flex flex-col items-start">
          <p>
            {item?.reference} - {transactorType}
          </p>
          <p className="text-sm text-primary">
            ${parseFloat(item?.amount)} -{' '}
            {(item?.payment_status === 'paid'
              ? 'In Trust'
              : item?.payment_status ?? ''
            ).toUpperCase()}
          </p>
        </div>
        <div className="flex flex-col items-end">
          <p className="text-sm">
            {format(
              new Date(item?.created_at),
              DateTimeFormats.DisplayDateTimeShort
            )}
          </p>
          <p className="text-sm text-primary">
            {item?.public_status?.toUpperCase()}
          </p>
        </div>
      </button>
    );
  };

  return (
    <div className="flex w-full flex-col gap-4 bg-white p-5">
      <h2 className="text-xl font-semibold">Transactions</h2>
      <div className="mt-4">
        {!dealsData || dealsData.length <= 0 || isLoading ? (
          <div className="flex w-full flex-1 items-center justify-center">
            <p>No tansactions available.</p>
          </div>
        ) : (
          <div className="flex flex-col gap-2">{dealsData.map(renderItem)}</div>
        )}
      </div>
    </div>
  );
};

export default Transactions;
