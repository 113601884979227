import Auth from 'layout/auth.layout';
import Main from 'layout/main.layout';
import Dashboard from 'pages/auth/Dashboard';
import AcceptDeal from 'pages/auth/AcceptDeal';
import InviteUser from 'pages/auth/InviteUser';
import RequestFunds from 'pages/auth/RequestFunds';
import SendFunds from 'pages/auth/SendFunds';
import TransactionDetails from 'pages/auth/TransactionDetails';
import Transactions from 'pages/auth/Transactions';
import TransferPayment from 'pages/auth/TransferPayment';
import ViewDetails from 'pages/auth/ViewDetails';
import GetStarted from 'pages/nonauth/GetStarted';
import Login from 'pages/nonauth/Login';
import Registration from 'pages/nonauth/Registration';
import TwoFactor from 'pages/onboarding/TwoFactor';
import Verification from 'pages/onboarding/Verification';
import VerificationPrompt from 'pages/onboarding/VerificationPrompt';
// import Login from "pages/nonauth/Login";
// import { Home, Deals, NotFound } from 'pages';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RouteGuard } from './RouteGuard';
import Onboarding from 'layout/onboarding.layout';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <RouteGuard>
              <Main />
            </RouteGuard>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="home" element={<Dashboard />} />
          <Route path="send-funds" element={<SendFunds />} />
          <Route path="request-funds" element={<RequestFunds />} />
          <Route path="transaction-details" element={<TransactionDetails />} />
          <Route path="transfer-payment" element={<TransferPayment />} />
          <Route path="invite-user" element={<InviteUser />} />
          <Route path="accept-deal" element={<AcceptDeal />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="account" element={<ViewDetails />} />
        </Route>
        <Route
          path="/"
          element={
            <RouteGuard>
              <Onboarding />
            </RouteGuard>
          }
        >
          <Route path="two-factor" element={<TwoFactor />} />
          <Route path="verification-prompt" element={<VerificationPrompt />} />
          <Route path="verification" element={<Verification />} />
        </Route>
        <Route path="/" element={<Auth />}>
          <Route index element={<GetStarted />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Registration />} />
        </Route>

        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
