// TODO: Export these to env
export const API_BASE_URL = 'https://staging.api.goescrow.app/api';
export const GOOGLE_API_KEY = 'AIzaSyC-exG2QAB3slR19vwudqNbqPfnmEtoqTI';

export const API_ENDPOINTS = {
  // User
  USER_CREATE: 'users/monoova',
  USER_DETAILS: 'users/me',
  USER_BASIC_DETAILS: 'users/details',
  USER_ADDRESS: 'users/address',
  USER_IDENTITY: 'users/identification',
  USER_BANK_DETAILS: 'users/bank',
  USER_PAYMENT: 'users/paymentDetails',
  USER_LOOKUP: 'users/lookup',
  USER_INVITE: 'users/invite',

  DEALS: 'deals',
  DEALS_FEES: 'deals/fees',
  DEALS_TOKEN: 'deals/token',
};
