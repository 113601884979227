/* eslint camelcase: 0 */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import React, { useCallback } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

import FormInput from 'components/FormInput';
import { useForm } from 'react-hook-form';
import { parseBsbNumber } from 'utils/parsers';

type Props = {
  onContinue?: (data: any) => void;
  data?: any;
  readonly?: boolean;
};

const BankDetails: React.FC<Props> = ({ onContinue, data, readonly }) => {
  const schema = yup
    .object({
      bsb_number: yup.string().required('BSB Number required'),
      account_number: yup.string().required('Account Number required'),
      account_name: yup.string().required('Account Name required'),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    ...(readonly ? { defaultValues: data } : {}),
  });

  const handleContinue = useCallback(
    async (formData: any) => {
      if (!onContinue || !formData) return;

      onContinue({
        ...(formData ? formData : data),
        bsb_number: parseBsbNumber(
          formData ? formData.bsb_number : data.bsb_number
        ),
      });
    },
    [onContinue]
  );

  return (
    <form className="my-3 w-full" onSubmit={handleSubmit(handleContinue)}>
      <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
        <div className="flex-1 flex-col space-y-3">
          <FormInput
            control={control}
            disabled={readonly}
            name="bsb_number"
            label="BSB Number"
            type="number"
            placeholder="BSB Number (e.g 999-999)"
          />
          <FormInput
            control={control}
            disabled={readonly}
            name="account_number"
            label="Account Number"
            type="number"
            placeholder="Account Number"
          />
          <FormInput
            control={control}
            disabled={readonly}
            name="account_name"
            label="Account Name"
            type="text"
            placeholder="Account Name"
          />
        </div>
      </div>

      {!readonly ? (
        <div className="mt-6 w-full sm:w-1/2 sm:pr-2">
          <button
            disabled={!isValid}
            className="btn-main w-full self-center py-2 sm:self-start"
          >
            <div className="flex flex-row justify-center">
              <h2 className="text-xl font-semibold">Continue</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-3xl text-white" />
            </div>
          </button>
        </div>
      ) : null}
    </form>
  );
};

export default BankDetails;
