import { useAmplifyAuth } from 'hooks/auth-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from './routes';

interface RouteGuardProps {
  children: ReactNode;
}

export const RouteGuard = ({ children }: RouteGuardProps) => {
  const navigate = useNavigate();
  const { getAuth } = useAmplifyAuth();
  const { doLoginNavigations } = useUserRequests();

  useEffect(() => {
    guard();
  }, []);

  const guard = async () => {
    console.log('[ROUTEGUARD] hit');
    const authData = await getAuth();
    if (authData) {
      doLoginNavigations();
    } else {
      navigate(ROUTES.LOGIN);
    }
  };

  return <>{children}</>;
};
