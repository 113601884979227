import { useQuery } from '@tanstack/react-query';
import TextInput from 'components/TextInput';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastError } from 'utils/toast';

const AcceptDeal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dealId = location?.state?.dealId || undefined;

  const { getDealDetails, acceptDeal, dealNavigationHelper } =
    useDealsRequests();
  const { getUserDetails } = useUserRequests();

  const { data: dealData, refetch } = useQuery(['dealDetails', dealId], () =>
    getDealDetails(dealId)
  );

  const { data: userData } = useQuery(['userDetails'], getUserDetails);

  const dealDetails = useMemo(
    () => (dealData && dealData.status ? dealData.data : {}),
    [dealData]
  );
  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  useEffect(() => {
    if (dealDetails?.status !== 'pending')
      dealNavigationHelper(dealDetails, userDetails, true);
  }, [dealDetails]);

  const handleAccept = useCallback(async () => {
    const res = await acceptDeal(dealId);
    if (res.status) {
      refetch();
    } else {
      toastError();
    }
  }, [dealId, dealDetails]);

  const handleCancel = useCallback(() => {
    //  TODO: Handle cancellation flow here
  }, []);

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 bg-white">
      <div className="flex flex-col text-center">
        <h2 className="text-xl font-semibold">Accept Transaction</h2>
        <p className="text-sm">
          Another party has requested you to enter into a GoESCROW transaction
          wiht them with you acting as the seller or supplier of services.
        </p>
        <p className="text-sm">
          If you agree to supply the item as described for the item price listed
          you can accept this transaction.
        </p>
      </div>

      <div className="mt-3 flex w-1/2 flex-col gap-2">
        <label className="text-lg font-semibold text-primary">
          Other Party Details
        </label>
        <div className="flex w-full flex-row justify-between border bg-white px-4 py-3 shadow-lg">
          <p className="font-medium">{dealDetails?.user?.reference}</p>
        </div>
      </div>

      <div className="mt-3 flex w-1/2 flex-col gap-2">
        <p className="text-lg font-semibold text-primary">Item Detail</p>
        <div className="flex w-full flex-row justify-between border bg-white px-4 py-3 shadow-lg">
          <p className="font-medium">Description</p>
          <p className="font-medium">{dealDetails?.description}</p>
        </div>

        <div className="flex w-full flex-row justify-between border bg-white px-4 py-3 shadow-lg">
          <p className="font-medium">Item Price</p>
          <p className="font-medium">
            ${parseFloat(dealDetails?.amount).toFixed(2)}
          </p>
        </div>

        <h2 className="mt-2 text-right text-xl font-bold text-primary">
          Total ${parseFloat(dealDetails?.amount).toFixed(2)}
        </h2>
      </div>

      <div className="mt-4 flex w-1/2 flex-col gap-2">
        <button className="btn-main" onClick={handleAccept}>
          Accept
        </button>
        <button
          className="w-full rounded-lg bg-secondary px-4 py-3 font-bold text-white hover:bg-secondary-120"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AcceptDeal;
