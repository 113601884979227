import { useQuery } from '@tanstack/react-query';
import ActiveDealsWidget from 'components/widgets/ActiveDealsWidget';
import FundsActionsWidget from 'components/widgets/FundsActionsWidget';
import { useUserRequests } from 'hooks/user-hooks';
import { useEffect, useMemo } from 'react';

const Dashboard = () => {
  const { getUserDetails, doLoginNavigations } = useUserRequests();

  const { data: userData } = useQuery(['userDetails'], getUserDetails);

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : undefined),
    [userData]
  );

  useEffect(() => {
    if (
      !userDetails ||
      !(
        userDetails.birth_date &&
        userDetails.address &&
        userDetails.identifications.length > 0
      )
    ) {
      doLoginNavigations();
    }
  }, []);

  return (
    <div className="flex w-full flex-col md:flex-row md:space-x-4">
      <div className="mb-4 flex flex-1 flex-col items-stretch space-y-4 md:mb-0">
        <FundsActionsWidget />
        <ActiveDealsWidget
          title="Recent Transactions"
          status={'cancelled,completed'}
        />
      </div>
      <div className="flex flex-1 flex-col  items-stretch space-y-4">
        <ActiveDealsWidget
          title="Active Transactions"
          status={'pending,accepted'}
        />
        {/* <SpecialOfferWidget /> */}
      </div>
    </div>
  );
};

export default Dashboard;
