import React from 'react';

import heroimg from 'assets/images/upload-documents-banner.svg';
import styles from 'utils/styles';
import { Link } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

const VerificationPrompt = () => {
  return (
    <div className="flex w-full flex-col py-5 px-6 sm:flex-row sm:px-2">
      <img
        src={heroimg}
        alt="Hero"
        className="my-5 flex h-auto w-auto flex-1 self-center"
      />
      <div
        className={`flex flex-1 flex-col ${styles.flexCenter} mt-5 space-y-3 text-center sm:mt-0 sm:items-start sm:text-left`}
      >
        <div>
          <h1 className="text-3xl font-semibold text-secondary  sm:text-3xl">
            Let me verify your identity
          </h1>
          <h2 className="mt-2 text-xl font-light">
            Your GoEscrow account has been created.
          </h2>
        </div>

        {/* Temporary navigate, replace with button type submit later on */}
        <Link to="/verification" className="w-full self-center sm:self-start">
          <button className="btn-main mt-8 w-full py-2">
            <div className="flex flex-row justify-center">
              <h2 className="text-xl font-semibold">Continue</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-3xl text-white" />
            </div>
          </button>
        </Link>

        <p className="w-full text-center text-lg font-semibold text-primary">
          <Link to="/login">Log Out</Link>
        </p>
      </div>
    </div>
  );
};

export default VerificationPrompt;
