import { Dialog } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import Modal from 'components/Modal';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastError } from 'utils/toast';

const TransferPayment = () => {
  const navigate = useNavigate();
  const { getUserPaymentDetails } = useUserRequests();
  const location = useLocation();
  const { getUserDetails } = useUserRequests();
  const [showPoliModal, setShowPoliModal] = useState<boolean>(false);
  const [showPoliDisclaimer, setShowPoliDisclaimer] = useState<boolean>(false);
  const [poliUrl, setPoliUrl] = useState<string>('');
  const [showBsbAccountModal, setShowBsbAccountModal] =
    useState<boolean>(false);
  const [showPayIdModal, setShowPayIdModal] = useState<boolean>(false);
  // TODO: Temporary workaround for resetting location state issue
  const [dealId, setDealId] = useState<string>(location.state.dealId);
  const { data: userData } = useQuery(['userDetails'], getUserDetails);

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const { getDealDetails, dealNavigationHelper, bypassPayment, payByPoli } =
    useDealsRequests();

  const { data: paymentData, isLoading: paymentLoading } = useQuery(
    ['userPaymentDetails'],
    getUserPaymentDetails
  );

  const {
    data: dealData,
    isLoading: dealLoading,
    refetch,
  } = useQuery(['dealDetails', dealId], () => getDealDetails(dealId));

  const dealDetails = useMemo(
    () => (dealData && dealData.status ? dealData.data : {}),
    [dealData]
  );

  const isLoading = useMemo(
    () => paymentLoading || dealLoading,
    [paymentLoading, dealLoading]
  );

  const hasToInvite = useMemo(
    () =>
      dealDetails &&
      dealDetails.initiator === 'me' &&
      !dealDetails?.other_user &&
      !dealDetails?.match,
    [dealDetails]
  );

  useEffect(() => {
    if (dealDetails?.payment_status !== 'pending')
      dealNavigationHelper(dealDetails, userDetails, true);
  }, [dealDetails, userDetails]);

  const handleBypassPay = useCallback(async () => {
    const res = await bypassPayment(dealId);
    if (res.status) {
      refetch();
    } else {
      toastError();
    }
  }, [dealId]);

  // const handlePayByPoli = useCallback(async () => {
  //   const res = await payByPoli(dealId);

  //   if (res?.status && res?.data) {
  //     setPoliUrl(res?.data);
  //     setShowPoliModal(true);
  //   }
  // }, [dealId]);

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 bg-white">
      <div className="flex flex-col gap-2">
        <h2 className="text-center text-lg font-semibold">
          GoESCROW Send Funds Active
        </h2>
        <p className="text-center text-sm">
          Emails and SMS have been sent to you and your seller and you may track
          deals in the Transactions Menu
        </p>
      </div>

      <div className="mt-4 flex flex-col gap-6">
        <h2 className="text-center text-xl text-primary">
          Transfer Amount to GoESCROW
        </h2>

        <button
          onClick={() => {
            setShowBsbAccountModal(true);
          }}
          className="rounded border-2 border-primary py-2 px-4 font-semibold text-primary"
        >
          Pay by BSB / Account Number
        </button>
        <button
          onClick={() => {
            setShowPayIdModal(true);
          }}
          className="rounded border-2 border-primary py-2 px-4 font-semibold text-primary"
        >
          Pay by PayID
        </button>
        <button
          // TODO: Temp disable poli
          disabled
          onClick={() => {
            setShowPoliModal(true);
          }}
          className="rounded border-2 border-primary py-2 px-4 font-semibold text-primary"
        >
          Pay by POLi
        </button>
      </div>

      <div className="flex flex-col gap-3">
        <p className="text-center">
          The seller will be notified by Email, SMS and by App
        </p>

        <p className="text-center text-primary">
          You will be required to authorise final Settlement after you have
          received your goods or services.
        </p>
      </div>

      <div>
        <button className="btn-main" onClick={handleBypassPay}>
          Dev Bypass Payment
        </button>
      </div>

      {/* BSB MOdal */}
      <Modal
        isOpen={showBsbAccountModal}
        onClose={() => setShowBsbAccountModal(false)}
      >
        <Dialog.Title
          as="h3"
          className="text-center text-lg font-medium leading-6 text-primary"
        >
          Transfer Amount to GoESCROW
        </Dialog.Title>

        <div className="mt-2 flex flex-col gap-2">
          <p className="text-center text-sm">
            Trust Account for this Transaction via BSB and Account Number
          </p>

          <div className="flex items-center justify-center rounded-lg bg-neutral-200 py-3 px-4  text-start">
            <div className="flex flex-col items-start justify-center text-start">
              <div className="inline-block">
                Send{'  '}
                <span className="font-bold text-primary">
                  $
                  {parseFloat(dealDetails?.remaining_payment_amount).toFixed(2)}
                </span>
              </div>
              <div className="inline-block">
                BSB:{'  '}
                <span className="font-bold text-primary">
                  {paymentData?.data?.bsb}
                </span>
              </div>
              <div className="inline-block">
                ACC:{'  '}
                <span className="font-bold text-primary">
                  {paymentData?.data?.bankAccountNumber}
                </span>
              </div>
              <div className="inline-block">
                NAME:{'  '}
                <span className="font-bold text-primary">
                  {paymentData?.data?.bankAccountName}
                </span>
              </div>
              <div className="inline-block">
                REF:{'  '}
                <span className="font-bold text-primary">
                  {dealDetails?.reference}
                </span>
              </div>
            </div>
          </div>

          <div className="mt-8 flex flex-1">
            <button
              onClick={() => setShowBsbAccountModal(false)}
              className="w-full rounded border-2 border-primary py-2 px-4 font-semibold text-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* PayID MOdal */}
      <Modal isOpen={showPayIdModal} onClose={() => setShowPayIdModal(false)}>
        <Dialog.Title
          as="h3"
          className="text-center text-lg font-medium leading-6 text-primary"
        >
          Transfer Amount to GoESCROW
        </Dialog.Title>

        <div className="mt-2 flex flex-col gap-2">
          <p className="text-center text-sm">
            Trust Account for this Transaction via PayID
          </p>

          <div className="flex items-center justify-center rounded-lg bg-neutral-200 py-3 px-4">
            <div className="inline-block justify-center text-center">
              Send{' '}
              <span className="font-semibold text-primary">
                ${parseFloat(dealDetails?.remaining_payment_amount).toFixed(2)}
              </span>{' '}
              to{' '}
              <span className="font-semibold text-primary">
                {' '}
                {paymentData?.data?.payId}
              </span>
              <br />
              with reference{' '}
              <span className="font-semibold text-primary">
                {dealDetails?.reference}
              </span>
            </div>
          </div>
          <div className="mt-8 flex flex-1">
            <button
              onClick={() => setShowPayIdModal(false)}
              className="w-full rounded border-2 border-primary py-2 px-4 font-semibold text-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransferPayment;
