import { Dialog, Disclosure, Popover } from '@headlessui/react';
import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Modal from 'components/Modal';
import TextInput from 'components/TextInput';
import NotesSection from 'components/NotesSection';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useQuery } from '@tanstack/react-query';
import { toastError } from 'utils/toast';
import { DateTimeFormats } from 'utils/parsers';
import {
  PaymentStatusExplainers,
  TransactionStatusExplainers,
} from 'constants/strings';

const TransactionDetails = () => {
  const navigate = useNavigate();
  const [isTickedCheckbox, setCheckboxTick] = useState<boolean>(false);
  const [isDealClosedModalShown, setDealClosedModalShown] =
    useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isReleaseFundsModalShown, setReleaseFundsModalShown] =
    useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [invalidCode, setInvalidCode] = useState<boolean>(false);

  const location = useLocation();

  const dealId = location?.state?.dealId || undefined;

  const { getDealDetails, closeDeal } = useDealsRequests();

  const {
    data: dealData,
    refetch,
    isLoading,
  } = useQuery(['dealDetails', dealId], () => getDealDetails(dealId));

  const dealDetails = useMemo(
    () => (dealData && dealData.status ? dealData.data : {}),
    [dealData]
  );

  const handleCloseDealModal = useCallback(() => {
    setDealClosedModalShown(false);
  }, []);

  const handleCloseReleaseFundsModal = useCallback(() => {
    setReleaseFundsModalShown(false);
  }, []);

  const handleCloseDeal = useCallback(async () => {
    setIsSubmitting(true);
    setInvalidCode(false);
    setCode('');
    const res = await closeDeal(dealId, code);

    if (res?.data?.two_factor_required) {
      setIsSubmitting(false);
      setTimeout(() => {
        setReleaseFundsModalShown(true);
      }, 1000);
      return;
    }

    if (res?.data?.two_factor_invalid) {
      setIsSubmitting(false);
      setInvalidCode(true);
      return;
    }

    if (res.status) {
      setIsSubmitting(false);

      setTimeout(() => {
        setReleaseFundsModalShown(false);

        setTimeout(() => {
          setDealClosedModalShown(true);
        }, 500);
      }, 1000);
    } else {
      setIsSubmitting(false);
      toastError();
    }
  }, [dealId, code]);

  const dealAccepted = useMemo(
    () => dealDetails?.status === 'accepted',
    [dealDetails]
  );
  const hasToInvite = useMemo(
    () => dealDetails && !dealDetails?.other_user && !dealDetails?.match,
    [dealDetails]
  );

  const isCloseDealDisabled = useMemo(
    () => !isTickedCheckbox || !dealAccepted,
    [isTickedCheckbox, dealAccepted]
  );

  const isInitiator = useMemo(
    () => dealDetails && dealDetails.initiator === 'me',
    [dealDetails]
  );

  const dealNotes = useMemo(
    () => (dealDetails && dealDetails.notes ? dealDetails.notes : []),
    [dealDetails]
  );

  const releaseDate = useMemo(
    () =>
      dealDetails && dealDetails.type === 'time' && dealDetails.release_at
        ? format(
            new Date(dealDetails.release_at),
            DateTimeFormats.DisplayDateTimeLong
          )
        : null,
    [dealDetails]
  );

  const dealType = useMemo(() => {
    if (!dealDetails || !dealDetails.type) return '';
    return dealDetails.type === 'anytime' ? 'Anytime' : 'Timed Escrow';
  }, [dealDetails]);

  const isBuyer = useMemo(
    () =>
      (isInitiator && dealDetails?.transaction_type === 'send') ||
      (!isInitiator && dealDetails?.transaction_type === 'request'),
    [dealDetails]
  );

  const showInviteCard = useMemo(
    () => isInitiator && hasToInvite && dealDetails?.status === 'pending',
    [hasToInvite, isInitiator, dealDetails]
  );

  const showAwaitingDealAcceptance = useMemo(
    () =>
      dealDetails &&
      dealDetails.initiator === 'me' &&
      dealDetails.status === 'pending',
    [dealDetails]
  );

  const showCloseDeal = useMemo(
    () =>
      ((isInitiator && dealDetails?.transaction_type === 'send') ||
        (!isInitiator && dealDetails?.transaction_type === 'request')) &&
      dealDetails?.status === 'accepted' &&
      dealDetails?.payment_status === 'paid',
    [isInitiator, dealDetails]
  );

  const transactionStatusExplainer = useMemo(() => {
    if (['pending', 'accepted', 'declined'].includes(dealDetails.status)) {
      return TransactionStatusExplainers[
        `${dealDetails.status}-${isBuyer ? 'buyer' : 'seller'}`
      ];
    }
    return TransactionStatusExplainers[dealDetails.status] || '';
  }, [dealDetails.status, isBuyer]);

  const paymentStatusExplainer = useMemo(() => {
    if (['pending', 'partially-funded'].includes(dealDetails.payment_status)) {
      return PaymentStatusExplainers[
        `${dealDetails.payment_status}-${isBuyer ? 'buyer' : 'seller'}`
      ];
    }
    return PaymentStatusExplainers[dealDetails.payment_status] || '';
  }, [dealDetails.payment_status, isBuyer]);

  const toggleCheckbox = useCallback(() => {
    setCheckboxTick(!isTickedCheckbox);
  }, [isTickedCheckbox]);

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 bg-white">
      <h2 className="text-xl font-semibold">
        Transaction ID:{' '}
        <span className="font-semibold text-primary">
          {dealDetails?.reference}
        </span>
      </h2>

      <div className="flex w-full flex-col overflow-auto border sm:w-1/2">
        <div className="flex flex-row justify-between border-b">
          <div className="flex-1 px-3 py-2">
            <span className="text-lg font-semibold text-primary">Type</span>
          </div>
          <div className="flex-1 px-3 py-2">
            <p className="text-center text-lg"> {dealType}</p>
          </div>
        </div>
        <div className="flex flex-row justify-between border-b">
          <div className="flex-1 px-3 py-2">
            <span className="break-words text-lg font-semibold text-primary">
              Transaction Status
            </span>
          </div>
          <div className="flex flex-1 flex-row items-center justify-between px-3 py-2">
            <p className="w-full text-center text-lg">
              {' '}
              {(!dealDetails?.other_user?.id
                ? 'Invite Pending'
                : dealDetails?.public_status || ''
              ).toUpperCase()}
            </p>
            {dealDetails.status && (
              <Popover className="relative">
                <Popover.Button
                  type="button"
                  className="inline-flex items-center align-middle"
                >
                  <AiOutlineInfoCircle className="text-primary" />
                </Popover.Button>
                <Popover.Panel
                  className={
                    'absolute left-1/2 z-30 mt-3 w-screen max-w-sm -translate-x-1/2 rounded bg-neutral-300 px-4 text-black'
                  }
                >
                  <div className="absolute left-[11.4rem] top-[-8px] h-5 w-5 rotate-45 bg-neutral-300" />
                  <div className="flex w-full flex-row items-center gap-4 rounded-t-lg bg-neutral-300 p-3 text-black">
                    {transactionStatusExplainer}
                  </div>
                </Popover.Panel>
              </Popover>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between border-b">
          <div className="flex-1 px-3 py-2">
            <span className="break-words text-lg font-semibold text-primary">
              Payment Status
            </span>
          </div>
          <div className="flex flex-1 flex-row items-center justify-between px-3 py-2">
            <p className="w-full text-center text-lg">
              {' '}
              {dealDetails.public_payment_status
                ? dealDetails?.public_payment_status.toUpperCase()
                : ''}
            </p>
            {dealDetails.payment_status && (
              <Popover className="relative">
                <Popover.Button
                  type="button"
                  className="inline-flex items-center align-middle"
                >
                  <AiOutlineInfoCircle className="text-primary" />
                </Popover.Button>
                <Popover.Panel
                  className={
                    'absolute left-1/2 z-30 mt-3 w-screen max-w-sm -translate-x-1/2 rounded bg-neutral-300 px-4 text-black'
                  }
                >
                  <div className="absolute left-[11.4rem] top-[-8px] h-5 w-5 rotate-45 bg-neutral-300" />
                  <div className="flex w-full flex-col items-center gap-4 rounded-t-lg bg-neutral-300 p-3 text-black">
                    {paymentStatusExplainer}
                  </div>
                </Popover.Panel>
              </Popover>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between border-b">
          <div className="flex-1 px-3 py-2">
            <span className="text-lg font-semibold text-primary">Amount</span>
          </div>
          <div className="flex-1 px-3 py-2">
            <p className="text-center text-lg">
              ${parseFloat(dealDetails?.amount)}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-between border-b">
          <div className="flex-1 px-3 py-2">
            <span className="text-lg font-semibold text-primary">
              Date Created
            </span>
          </div>
          <div className="flex-1 px-3 py-2">
            <p className="text-center text-lg">
              {dealDetails?.created_at
                ? format(
                    new Date(dealDetails?.created_at),
                    DateTimeFormats.DisplayDateTimeShort
                  )
                : ''}
            </p>
          </div>
        </div>
        <div className="flex flex-col border-b text-left">
          <div className="flex-1 px-3 py-2">
            <span className="text-lg font-semibold text-primary">
              Description
            </span>
          </div>
          <div className="flex-1 px-3 py-2">
            <p className="text-lg">{dealDetails?.description}</p>
          </div>
        </div>
        <div className="flex flex-col border-b text-left">
          <div className="flex-1 px-3 py-2">
            <span className="text-lg font-semibold text-primary">
              Other Party GoEscrow ID, Email or Mobile
            </span>
          </div>
          <div className="flex-1 px-3 py-2">
            <p className="text-lg">{dealDetails?.user_reference}</p>
          </div>
        </div>
        <div className="flex flex-col border-b text-left">
          <NotesSection notes={dealNotes} dealId={dealId} refetch={refetch} />
        </div>
      </div>

      {showAwaitingDealAcceptance && (
        <h2 className="mt-4 text-center text-xl font-semibold">
          Awaiting Transaction Acceptance
        </h2>
      )}

      {showCloseDeal ? (
        <>
          {isBuyer && releaseDate && (
            <p className=" mt-6 text-left italic">
              To settle this transaction now rather than wait for the above
              settlement time:
            </p>
          )}
          <div className="mt-3 flex flex-row items-center">
            <input
              id="shareNote"
              type={'checkbox'}
              className="h-4 w-4 border-2"
              checked={isTickedCheckbox}
              onChange={toggleCheckbox}
            />
            <button onClick={toggleCheckbox}>
              <p className="mt-2 ml-4 mr-5 font-light">
                {isBuyer && releaseDate
                  ? 'I authorize early Final Settlement as I have received the Goods or Services or both and have read the GoESCROW Terms and Conditions.'
                  : 'I authorise GoESCROW to settle funds held in trust to the seller.'}
              </p>
            </button>
          </div>

          <button
            className="btn-main mt-5"
            onClick={handleCloseDeal}
            disabled={isCloseDealDisabled}
          >
            <h3 className="text-white">Close Transaction</h3>
          </button>
        </>
      ) : null}

      {/* close deal modal */}
      <Modal isOpen={isDealClosedModalShown} onClose={handleCloseDealModal}>
        <Dialog.Title
          as="h3"
          className="items-center justify-center text-center text-lg font-medium leading-6 text-primary"
        >
          Transaction Closed
        </Dialog.Title>

        <div className="mt-2 flex flex-col gap-2">
          <p>
            Thank you for completing the transaction. You have received your
            goods and you want to complete the deal.
            <br />
            The seller will be notified by Email, SMS and by App. Check the
            status of this Transaction in &apos;My Transactions&apos; Menu
          </p>
          <button className="btn-main mt-8" onClick={handleCloseDealModal}>
            <h3 className="text-white">Finish</h3>
          </button>
        </div>
      </Modal>

      {/* Release Funds modal */}
      <Modal
        isOpen={isReleaseFundsModalShown}
        onClose={handleCloseReleaseFundsModal}
      >
        <Dialog.Title
          as="h3"
          className="items-center justify-center text-center text-lg font-medium leading-6 text-primary"
        >
          Release Funds
        </Dialog.Title>

        <div className="mt-4 flex flex-col gap-2 text-center">
          <p>
            I have confirm receipt the relevant goods or services from the other
            party and understand that releasing funds is immediate and
            irreversible and irrevocable. I have read and agree to GoESCROW
            Terms and Conditions
          </p>

          <TextInput
            placeholder="Enter Six Digit Code"
            type="number"
            minLength={6}
            maxLength={6}
            value={code}
            disabled={isSubmitting}
            onChange={(e) => {
              setCode(e.currentTarget.value);
            }}
            className="mt-8 py-5 text-center text-2xl"
          />
          <button
            className="btn-main mt-8"
            onClick={handleCloseDeal}
            disabled={isSubmitting || !code || code.length !== 6}
          >
            <h3 className="text-white">Release Funds</h3>
          </button>
          <button
            className="btn-main bg-secondary"
            onClick={handleCloseReleaseFundsModal}
          >
            <h3 className="text-white">Cancel</h3>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TransactionDetails;
