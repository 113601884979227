export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  TWO_FACTOR: '/two-factor',
  VERIFICATION_PROMPT: '/verification-prompt',
  VERIFICATION: '/verification',

  HOME: '/home',
  SEND_FUNDS: '/send-funds',
  TRANSFER_PAYMENT: '/transfer-payment',
  TRANSACTION_DETAILS: '/transaction-details',
  INVITE_USER: '/invite-user',
  INVITED_DEAL: '/invited-deal',
  ACCEPT_DEAL: '/accept-deal',

  REQUEST_FUNDS: '/request-funds',

  VIEW_DETAILS: '/account',
  TRANSACTIONS: '/transactions',
};
